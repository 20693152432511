<template>

    <b-card no-body>
        <div class="m-1">
            <b-row>
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                    <v-select
                            v-model="perPage"
                            class="per-page-selector d-inline-block mx-50"
                            :options="perPageOptions"
                            :clearable="false"/>
                </b-col>
                <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                        <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."/>
                        <header-table :new-route="{ name: 'cms-menu-add' }" :re-fetch-data="reFetchData"></header-table>
                    </div>
                </b-col>
            </b-row>
        </div>

        <b-table
                ref="refListTable"
                primary-key="id"
                empty-text="No record found"
                class="position-relative"
                :items="fetchList"
                :fields="tableColumns"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
                responsive striped bordered hover show-empty>

            <template #cell(id)="data">
                <id-column-table :data="data.value" :route-name="'cms-menu-edit'"></id-column-table>
            </template>

            <template #cell(menu_group)="data">
                {{ data.value === 1 ? 'Main Left' : data.value === 2 ? 'Main Right' : 'Footer' }}
            </template>

            <template #cell(status)="data">
                <active-column-table :data="data.value"></active-column-table>
            </template>

            <template #cell(actions)="data">
                <div class="text-nowrap">
                    <edit-button-table :data="data" :router-name="'cms-menu-edit'"></edit-button-table>
                    <remove-button-table :data="data" :re-fetch-data="reFetchData"></remove-button-table>
                </div>
            </template>

        </b-table>

        <div class="mx-2 mb-2">
            <b-row>
                <footer-table :data-meta="dataMeta"></footer-table>
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                    <b-pagination
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                            v-model="currentPage"
                            :total-rows="totalCount"
                            :per-page="perPage"
                            first-number last-number>

                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18"/>
                        </template>

                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18"/>
                        </template>

                    </b-pagination>
                </b-col>
            </b-row>
        </div>

        <Overlay :busy="busy"></Overlay>

    </b-card>

</template>

<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay,
} from 'bootstrap-vue'
import {
    FooterTable, RemoveButtonTable, EditButtonTable, DateColumnTable,
    ActiveColumnTable, BooleanColumnTable, IdColumnTable, HeaderTable
} from '@/components/Table';

import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from '@/store'
import useList from './useList'
import storeModule from './store'
import Overlay from "@/components/Overlay.vue";
import {avatarText, getApiFile} from "@core/utils/filter";

export default {
    components: {
        HeaderTable, FooterTable, RemoveButtonTable, EditButtonTable,
        DateColumnTable, ActiveColumnTable, BooleanColumnTable, IdColumnTable,

        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem,
        BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay,

        vSelect,
        Overlay,
    },
    setup() {
        const STORE_MODULE_NAME = 'store'
        if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
        onUnmounted(() => {
            if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
        })

        const {
            tableColumns,
            perPage,
            currentPage,
            totalCount,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            busy,

            fetchList,
            reFetchData,
            resolveUserRoleVariant,
            resolveUserRoleIcon,
        } = useList()

        return {
            tableColumns,
            perPage,
            currentPage,
            totalCount,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            busy,

            fetchList,
            reFetchData,
            avatarText,
            getApiFile,
            resolveUserRoleVariant,
            resolveUserRoleIcon,
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
